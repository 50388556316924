import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { ProgressBar } from 'react-bootstrap';
import PuzzleElements2 from './puzzles/PuzzleElements2';
import PuzzleOptometrist from './puzzles/PuzzleOptometrist';
import Year1039ContainerBackground from '../images/Year-1039-background.jpg';

// Clues
import YearsAlpha from '../components/clues/YearsAlpha';
import YearsDelta from '../components/clues/YearsDelta';
import YearsGamma from '../components/clues/YearsGamma';
import ElementInfoCards from '../components/clues/ElementInfoCards';
import CompoundRecipes from '../components/clues/CompoundRecipes';
import PeriodicTable from '../components/clues/PeriodicTable';
import Elements2Chart from '../components/clues/Elements2Chart';
import OptometristClues from '../components/clues/OptometristClues';
import TimeMachineHologrid from '../components/clues/TimeMachineHologrid';
import TimeTravelClue2 from '../components/clues/TimeTravelClue2';

// Travel Year Verification
import TravelYearVerification from './TravelYearVerification';

class Year1039 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            puzzleElements2: false,
            puzzleSkyscrapers: false,
            puzzleOptometrist: false,
            year: 1039
        }
    }

    updatePuzzleStatus = (puzzle) => {
        const puzzleStatus = puzzle;
        this.setState({
          [puzzleStatus]: true
        });
        this.props.updateTotalPuzzlesCompleted('add');
      }
    
    componentDidMount() {
        if (this.props.currentYear !== this.state.year) {
            const elements = {
                elson: 0,
                onoza: 0,
                robell: 0,
                titanson: 0,
                caslight: 0,
                gnoson: 1,
            }
            this.props.updateElementsInventory(elements, 'custom');
            this.props.updateCurrentYear(1039);
            this.props.updateTotalPuzzlesCompleted('custom', 1);
        }
    }

    render() {

        const yearContainer1039 = {
            backgroundImage: `url(${Year1039ContainerBackground})`,
            backgroundSize: 'cover'
        };

        const year1039 = {
            backgroundImage: `url("https://res.cloudinary.com/dqykhzxtl/image/upload/v1605235760/virtual-time-travel/Virtual_Time_Travel_Year_1039_r9mhf9.jpg")`
        }

        return (
            <div className="year-container" style={yearContainer1039}>
                { this.props.currentYear === this.state.year ? '' : <Redirect to={`/year-${this.props.currentYear}`} /> }

                <h1 className="text-center text-white">Year 1039</h1>
                <ProgressBar striped animated variant="warning" now={50} label={"PROGRESS: 50%"} />
                <div className="year-background" style={year1039}>
                    <PuzzleElements2
                        puzzleElements2={this.state.puzzleElements2}
                        updatePuzzleStatus={this.updatePuzzleStatus}

                        elementsInventory={this.props.elementsInventory}
                        updateElementsInventory={this.props.updateElementsInventory}

                        totalPuzzlesCompleted = {this.props.totalPuzzlesCompleted}
                        updateTotalPuzzlesCompleted={this.props.updateTotalPuzzlesCompleted}
                    />

                    {/* <PuzzleSkyscrapers
                        puzzleSkyscrapers={this.state.puzzleSkyscrapers}
                        updatePuzzleStatus={this.updatePuzzleStatus}
                        
                        elementsInventory={this.props.elementsInventory}
                        updateElementsInventory={this.props.updateElementsInventory}

                        totalPuzzlesCompleted = {this.props.totalPuzzlesCompleted}
                        updateTotalPuzzlesCompleted={this.props.updateTotalPuzzlesCompleted}
                    /> */}

                    <PuzzleOptometrist
                        puzzleOptometrist={this.state.puzzleOptometrist}
                        updatePuzzleStatus={this.updatePuzzleStatus}

                        elementsInventory={this.props.elementsInventory}
                        updateElementsInventory={this.props.updateElementsInventory}

                        totalPuzzlesCompleted = {this.props.totalPuzzlesCompleted}
                        updateTotalPuzzlesCompleted={this.props.updateTotalPuzzlesCompleted}
                    />

                    <YearsAlpha positioning={{ right: "19%", top: "25%"}}/>
                    <YearsDelta positioning={{ left: "4%", bottom: "47%"}}/>
                    <YearsGamma positioning={{ left: "45%", bottom: "33%"}}/>
                    <ElementInfoCards positioning={{ left: "15%", top: "15%"}}/>
                    <CompoundRecipes positioning={{ left: "32%", top: "22%" }} />
                    <PeriodicTable positioning={{ right: "5%", top: "8%" }} />
                    <Elements2Chart positioning={{ right: "9%", top: "28%" }} />
                    {/* <SkyscrapersClue positioning={{ right: "26%", bottom: "40%" }} /> */}
                    <OptometristClues positioning={{ left: "45%", top: "14%" }} />
                    <TimeMachineHologrid positioning={{ left: "28%", top: "45%" }} />
                    <TimeTravelClue2 positioning={{ left: "36%", top: "51%" }} />

                    <TravelYearVerification 
                        positioning={{ left: "20%", bottom: "40%" }}
                        currentYear = {this.state.year}
                    />
                </div>

            </div>
        )
    }
}

export default Year1039
