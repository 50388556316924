import React, { Component } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { InfoCircleFill } from 'react-bootstrap-icons';

class ElementsInventory extends Component {
    render() {
        const { caslight, elson, gnoson, onoza, robell, titanson } = this.props.elementsInventory;
        const popover = (
            <Popover className="popover-basic">
                <Popover.Title as="h3">Elements Inventory</Popover.Title>
                <Popover.Content>
                    <p>Your Elements Inventory shows a breakdown of all the elements that you have collected so far during your Virtual Time Travel Game.</p>
                    <p>To collect elements, you will be required to solve the puzzles in each time zone. For each puzzle you solve, you will collect different elements.</p>
                </Popover.Content>
            </Popover>
        );

        return (
            <div className="elements-inventory game-controls-single-component">
                <h5 className="text-center">
                    Elements Inventory
                    <div className="game-controls-info-icon">
                        <OverlayTrigger trigger="click" placement="auto" overlay={popover} rootClose={true} onToggle={() => this.setState({ error: null })}>
                            <InfoCircleFill className="lock-icon" alt="Elements Inventory Info Icon" />
                        </OverlayTrigger>
                    </div>
                </h5>
                <ul>
                    <li>Elson: {elson}</li>
                    <li>Onoza: {onoza}</li>
                    <li>Robell: {robell}</li>
                    <li>Titanson: {titanson}</li>
                    <li>Caslight: {caslight}</li>
                    <li>Gnoson: {gnoson}</li>
                </ul>


            </div>
        )
    }
}

export default ElementsInventory
