import React, { Component } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { LockFill } from 'react-bootstrap-icons';
import PuzzleSliderCaslight from './PuzzleSliderCaslight';
import gameService from '../../services/gameService';

export class PuzzleCaslight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passcodeInput: '',
      success: null,
      error: null,
      elementsReceived: null,
      sliderLoaded: false,
      tiles: {
        tile1: null,
        tile2: null,
        tile3: null,
        tile4: null,
        tile5: null,
        tile6: null
      },
      iconColor: "red"
    };
  }

  randomizeTiles = () => {
    const numberArray = [1, 2, 3, 4, 5];
    const tiles = { tile6: 0 };
    const tiles2 = { tile1: 5, tile2: 2, tile3: 3, tile4: 1, tile5: 4, tile6:0 };
    for (let i = 1; i <= 5; i++) {
      const number = numberArray.splice(Math.random() * numberArray.length, 1)[0];
      tiles[`tile${i}`] = number;
    }
    this.setState({ tiles: tiles2 });
  }

  tileClicked = async event => {
    if (!this.state.success) {
      const clickedTileId = event.target.id;
      this.tileMovementCheck(this.tileMapping(event.target.id), clickedTileId, this.state.tiles[clickedTileId]);

      if (this.checkSliderSuccess()) {
        const response = await gameService.puzzleCaslight();
        if (response.message === 'CORRECT') {
          this.props.updateElementsInventory(response.elements, 'add');
          this.setState({
            success: true,
            elementsReceived: response.elements,
            iconColor: "green"
          });
          this.props.updatePuzzleStatus('puzzleCaslight');
          alert('You have solved this puzzle!');
        }
      }
    }
  }

  tileMapping = (tileNumber) => {
    if (tileNumber === 'tile1') return [2, 4];
    if (tileNumber === 'tile2') return [1, 3, 5];
    if (tileNumber === 'tile3') return [2, 6];
    if (tileNumber === 'tile4') return [1, 5];
    if (tileNumber === 'tile5') return [2, 4, 6];
    if (tileNumber === 'tile6') return [3, 5];
  }

  tileMovementCheck = (tileMappingArray, clickedTile, clickedTileValue) => {
    const tiles = this.state.tiles;
    tileMappingArray.forEach(tilePosition => {
      if (tiles[`tile${tilePosition}`] === 0) {
        tiles[`tile${tilePosition}`] = clickedTileValue;
        tiles[clickedTile] = 0;
      }
    })
    this.setState({ tiles: tiles });
  }

  checkSliderSuccess = () => {
    const tiles = this.state.tiles;
    if (tiles.tile1 === 1 && tiles.tile2 === 2 && tiles.tile3 === 3 & tiles.tile4 === 4 && tiles.tile5 === 5 && tiles.tile6 === 0) {
      return true;
    } else {
      return false
    }
  }

  updateSliderLoaded = () => {
    this.setState({ sliderLoaded: true });
  }

  componentDidMount() {
    this.randomizeTiles();
  }

  render() {
    const popover = (
      <Popover className="popover-basic">
        <Popover.Title as="h3">Puzzle: Caslight</Popover.Title>
        <Popover.Content>

          <PuzzleSliderCaslight
            tiles={this.state.tiles}
            tileClicked={this.tileClicked}
            success = {this.state.success}
            elementsReceived = {this.state.elementsReceived}
          />
        </Popover.Content>
      </Popover>
    );

    return (
      <OverlayTrigger
        trigger="click"
        placement="auto"
        overlay={popover}
        rootClose={true}
        transition={false}
        onToggle={() => this.setState({ error: null })}
      >
        <div className="game-icons" id="puzzle-caslight" style={{color: this.state.iconColor}}>
          <LockFill alt="Puzzle Caslight" />
        </div>
      </OverlayTrigger>

    )
  }
}

export default PuzzleCaslight
