import React, { Component } from 'react';


class Year2020End extends Component {
    render() {

        const yearContainer2020End = {
            backgroundImage: `url("https://res.cloudinary.com/dqykhzxtl/image/upload/v1603263698/virtual-time-travel/Year-2020-End-background_okrtsk.jpg")`,
            backgroundSize: 'cover',
            width: "100%",
            height: "100vh"
        };

        return (
            <div className="year-container" style={yearContainer2020End}>
            </div>
        )
    }
}

export default Year2020End
