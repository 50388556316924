import React, { Component } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import PasscodeForm from './PasscodeForm';
import { LockFill } from 'react-bootstrap-icons';
import gameService from '../../services/gameService';
import PuzzleElements2Image from '../../images/Puzzle_Elements2.png';

class PuzzleElements2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
          passcodeInput: '',
          success: null,
          error: null,
          elementsReceived: null,
          iconColor: "red"
        };
      }
    
      handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });
      }
    
      handleSubmit = async event => {
        event.preventDefault();
        if (this.props.puzzleElements2) {
          console.log('already answered correctly');
        } else {
          const response = await gameService.puzzleElements2(this.state.passcodeInput);
          if (response.message === 'CORRECT') {
            this.props.updateElementsInventory(response.elements, 'add');
            this.setState({ 
              success: true,
              elementsReceived: response.elements,
              iconColor: "green"
            })
            this.props.updatePuzzleStatus('puzzleElements2');
          } else {
            this.setState({ error: response.message});
          }
        }
      }

      componentDidMount() {
        if (this.props.totalPuzzlesCompleted >= 5) this.setState({success: true});
      }
    
      render() {
        const popover = (
          <Popover className="popover-basic">
            <Popover.Title as="h3">Puzzle: Elements 2</Popover.Title>
            <Popover.Content>
              <img src={PuzzleElements2Image} className="popover-images" alt="Puzzle Elements 2" />
              <p><i>Hint: This puzzle is solved with 2 other related clue(s) in the room.</i></p>
              <PasscodeForm
                handleChange={this.handleChange}
                handleSubmit={this.handleSubmit}
                elementsReceived={this.state.elementsReceived}
                success={this.state.success}
                error={this.state.error}
                numberOfDigits={"(4-Digits)"}
              />
            </Popover.Content>
          </Popover>
        );
    
        return (
            <OverlayTrigger 
              trigger="click"
              placement="auto" 
              overlay={popover} 
              rootClose={true}
              transition={false}
              onToggle={() => this.setState({error: null})}
            >
              <div className="game-icons" id="puzzle-elements-2" style={{color: this.state.iconColor}}>
                <LockFill alt="Puzzle Elements 2 Lock" />
              </div>            
            </OverlayTrigger>
          
        )
      }
}

export default PuzzleElements2;
