import React, { Component } from 'react';
import { Col, Row, Form, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { InfoCircleFill } from 'react-bootstrap-icons';
import gameService from '../services/gameService';


class CompoundSynthesizer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            elsonQty: 0,
            onozaQty: 0,
            robellQty: 0,
            titansonQty: 0,
            caslightQty: 0,
            gnosonQty: 0,
            elsonQtyError: false,
            onozaQtyError: false,
            robellQtyError: false,
            titansonQtyError: false,
            caslightQtyError: false,
            gnosonQtyError: false
        }
    }

    displaySynthesizerInputBlank = (element) => {
        if (this.state[`${element}Qty`] === 0 ) {
            return '';
        } else {
            return this.state[`${element}Qty`];
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.elementsInventory !== prevProps.elementsInventory) {
            Object.keys(this.props.elementsInventory).forEach(element => {
                this.elementQuantityValidation(element, this.state[`${element}Qty`]);
            })
        }   
    }

    handleChange = event => {
        if (!event.target.value) {
            this.setState({ 
                [event.target.id]: 0,
                [`${event.target.name}QtyError`]: false
            });
        } else if ( parseInt(event.target.value) > this.props.elementsInventory[event.target.name] ) {
            this.setState( { 
                [`${event.target.name}QtyError`]: true,
                [event.target.id]: parseInt(event.target.value)
            })
        } else {
            this.setState({ 
                [event.target.id]: parseInt(event.target.value),
                [`${event.target.name}QtyError`]: false
            });
        }
    }


    elementQuantityValidation = (element, quantity) => {
        if (this.props.elementsInventory[element] < quantity) {
            this.setState({ [`${element}QtyError`]: true });
        } else {
            this.setState({ [`${element}QtyError`]: false });
        }
    }

    disableSynthesize = () => {
        return (
            (this.state.elsonQtyError || this.state.onozaQtyError || this.state.robellQtyError || this.state.titansonQtyError || this.state.caslightQtyError || this.state.gnosonQtyError) || 
            (!this.state.elsonQty && !this.state.onozaQty && !this.state.robellQty && !this.state.titansonQty && !this.state.caslightQty && !this.state.gnosonQty)
        )    
    }

    handleSubmit = async event => {
        event.preventDefault();
        const elementsInput = {
            elson: this.state.elsonQty,
            gnoson: this.state.gnosonQty,
            onoza: this.state.onozaQty,
            robell: this.state.robellQty,
            titanson: this.state.titansonQty,
            caslight: this.state.caslightQty
        }
        const response = await gameService.compoundSynthesizer(elementsInput);
        
        if (response.message === 'SUCCESS') {
            this.props.updateCompoundsInventory(response.compounds, 'add');
            this.props.updateElementsInventory(elementsInput, 'deduct');
            this.setState({
                elsonQty: 0,
                gnosonQty: 0,
                onozaQty: 0,
                robellQty: 0,
                titansonQty: 0,
                caslightQty: 0,
                elsonQtyError: false,
                gnosonQtyError: false,
                onozaQtyError: false,
                robellQtyError: false,
                titansonQtyError: false,
                caslightQtyError: false
            })

            const compoundName = Object.keys(response.compounds)[0];
            alert(`Congratulations! You have synthesized the following compound: ${compoundName[0].toUpperCase()}${compoundName.slice(1)}`);
        } else {
            alert(response.message);
        }
    }

    render() {
        const isDisabled = this.disableSynthesize();
        const formPlaceholder = "Enter Quantity: Leave blank if 0";

        const popover = (
            <Popover className="popover-basic">
                <Popover.Title as="h3">Compounds Synthesizer</Popover.Title>
                <Popover.Content>
                    <p>Compounds Synthesizer allows you to use elements from your Elements Inventory to synthesize and create different compounds.</p>
                    <p>For each time you synthesize your elements, you will create one compound.</p>
                    <p>Different compounds can be created by inputting different quantities of elements.</p>
                    <p>You will need to input the correct quantities of elements to synthesize a specific compound. <strong>Don't worry - if you key in the wrong input quantites, you will not deplete your Elements Inventory.</strong></p>
                    <p>After synthesizing a compound successfully, the compound will be added to your existing Compounds Inventory.</p>
                </Popover.Content>
            </Popover>
        );

        return (
            <div className="compound-synthesizer game-controls-single-component">
                <h5 className="text-center">
                    Compound Synthesizer
                    <div className="game-controls-info-icon">
                        <OverlayTrigger trigger="click" placement="auto" overlay={popover} rootClose={true} onToggle={() => this.setState({ error: null })}>
                            <InfoCircleFill className="lock-icon" alt="Compounds Synthesizer Info Icon" />
                        </OverlayTrigger>
                    </div>
                </h5>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Group controlId="elsonQty" as={Row}>
                        <Form.Label column sm={2}>Elson</Form.Label>
                        <Col sm={10}>
                            <Form.Control type="number" name="elson" placeholder={formPlaceholder} value={this.displaySynthesizerInputBlank('elson')} min="0" onChange={this.handleChange}/>
                            {this.state.elsonQtyError ?
                                    <Form.Text className="text-muted">
                                        Quantity exceeds current inventory level
                                    </Form.Text>
                                    : ''
                            }
                        </Col>
                        </Form.Group>
                        
                    <Form.Group controlId="onozaQty" as={Row}>
                        <Form.Label column sm={2}>Onoza</Form.Label>
                        <Col sm={10}>
                            <Form.Control type="number" name="onoza" placeholder={formPlaceholder} value={this.displaySynthesizerInputBlank('onoza')} min="0" onChange={this.handleChange}/>
                            {this.state.onozaQtyError ?
                                    <Form.Text className="text-muted">
                                        Quantity exceeds current inventory level
                                    </Form.Text>
                                    : ''
                            }
                        </Col>
                    </Form.Group>
                    <Form.Group controlId="robellQty" as={Row}>
                        <Form.Label column sm={2}>Robell</Form.Label>
                        <Col sm={10}>
                            <Form.Control type="number" name="robell" placeholder={formPlaceholder} value={this.displaySynthesizerInputBlank('robell')} min="0" onChange={this.handleChange}/>
                            {this.state.robellQtyError ?
                                    <Form.Text className="text-muted">
                                        Quantity exceeds current inventory level
                                    </Form.Text>
                                    : ''
                            }
                        </Col>
                    </Form.Group>
                    <Form.Group controlId="titansonQty" as={Row}>
                        <Form.Label column sm={2}>Titanson</Form.Label>
                        <Col sm={10}>
                            <Form.Control type="number" name="titanson" placeholder={formPlaceholder} value={this.displaySynthesizerInputBlank('titanson')} min="0" onChange={this.handleChange}/>
                            {this.state.titansonQtyError ?
                                    <Form.Text className="text-muted">
                                        Quantity exceeds current inventory level
                                    </Form.Text>
                                    : ''
                            }
                        </Col>                        
                    </Form.Group>
                    <Form.Group controlId="caslightQty" as={Row}>
                        <Form.Label column sm={2}>Caslight</Form.Label>
                        <Col sm={10}>
                            <Form.Control type="number" name="caslight" placeholder={formPlaceholder} value={this.displaySynthesizerInputBlank('caslight')} min="0" onChange={this.handleChange}/>
                            {this.state.caslightQtyError ?
                                    <Form.Text className="text-muted">
                                        Quantity exceeds current inventory level
                                    </Form.Text>
                                    : ''
                            }
                        </Col>
                    </Form.Group>
                    <Form.Group controlId="gnosonQty" as={Row}>
                        <Form.Label column sm={2}>Gnoson</Form.Label>
                        <Col sm={10}>
                            <Form.Control type="number" name="gnoson" value={this.displaySynthesizerInputBlank('gnoson')} placeholder={formPlaceholder} min="0" onChange={this.handleChange}/>
                            {this.state.gnosonQtyError ?
                                    <Form.Text className="text-muted">
                                        Quantity exceeds current inventory level
                                    </Form.Text>
                                    : ''
                            }
                        </Col>
                    </Form.Group>
                    <Button variant="primary" disabled={isDisabled} type="submit" >Synthesize</Button>
                </Form>
            </div>
        )
    }
}

export default CompoundSynthesizer
