import React, { Component } from 'react';
import JotformEmbed from 'react-jotform-embed';


class Introduction extends Component {
    componentDidMount() {
        // const script = document.createElement("script");
        // script.src = "https://app.getresponse.com/view_webform_v2.js?u=Svlqc&webforms_id=hGFad";
        // script.async = true;
        // // document.body.appendChild(script);
        // const getresponseForm = document.getElementById('getresponse-form');
        // getresponseForm.appendChild(script);
    }

    render() {
        return (
            <div className="general-container">
                <h1 className="text-center text-white">Introduction</h1>
                <div className="introduction">
                    <div className="intro-video">
                        <iframe title="The Fun Empire Virtual Time Travel Intro Video "width="560" height="315" src="https://www.youtube.com/embed/oR0MdkonVMs" frameborder="0" allow="accelerometer; autoplay; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    <div className="intro-form">
                        {/* Main Website Form */}
                        <JotformEmbed src="https://form.jotform.com/210101327948449" />

                        {/* Back-up Website Form */}                
                        {/* <JotformEmbed src="https://form.jotform.com/211751162756455" />  */}
                        
                    </div>
                    {/* <div className="intro-form" id="getresponse-form">
                    </div> */}
                </div>
            </div>
        )
    }
}

export default Introduction
