import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

export class WaitingRoom extends Component {
    render() {
        return (
            <div className="waiting-room-container">
                <h1 className="text-center text-white">Waiting Room</h1>
                <div className="waiting-room">
                    <Link id="waiting-room-button" to="/introduction"><Button variant="success">Proceed To Introduction</Button></Link>
                </div>
            </div>
        )
    }
}

export default WaitingRoom
