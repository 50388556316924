// Dependencies
import React, { Component } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import PasscodeForm from './PasscodeForm';
import { LockFill } from 'react-bootstrap-icons';
import gameService from '../../services/gameService';

class PuzzleWordChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
          passcodeInput: '',
          success: null,
          error: null,
          elementsReceived: null,
          iconColor: "red"
        };
      }
    
      handleChange = event => {
        this.setState({
          [event.target.id]: event.target.value
        })
      }
    
      handleSubmit = async event => {
        event.preventDefault();
        if (this.props.puzzleWordChart) {
          console.log('already answered correctly');
        } else {
          const response = await gameService.puzzleWordChart(this.state.passcodeInput);
          if (response.message === 'CORRECT') {
            this.props.updateElementsInventory(response.elements, 'add');
            this.setState({ 
              success: true,
              elementsReceived: response.elements,
              iconColor: "green"
            })
            this.props.updatePuzzleStatus('puzzleWordChart');
          } else {
            this.setState({ error: response.message});
          }
        }
      }

      componentDidMount() {
        if (this.props.totalPuzzlesCompleted >= 2) this.setState({success: true});
      }
    
      render() {
        const popover = (
          <Popover className="popover-basic">
            <Popover.Title as="h3">Puzzle: Word Chart</Popover.Title>
            <Popover.Content>
            <img src="https://res.cloudinary.com/dqykhzxtl/image/upload/v1603707831/virtual-time-travel/Puzzle_Word_Chart_cm6pxh.jpg" className="popover-images" alt="Puzzle Word Chart" />
            <p><i>Hint: This puzzle is solved with 1 other related clue(s) in the room.</i></p>
              <PasscodeForm
                handleChange={this.handleChange}
                handleSubmit={this.handleSubmit}
                success={this.state.success}
                error={this.state.error}
                elementsReceived={this.state.elementsReceived}
                numberOfDigits={"(3-Digits)"}
              />
            </Popover.Content>
          </Popover>
        );
    
        return (
            <OverlayTrigger 
              trigger="click"
              placement="auto" 
              overlay={popover} 
              rootClose={true}
              transition={false}
              onToggle={() => this.setState({error: null})}
            >
              <div className="game-icons" id="puzzle-word-chart" style={{color: this.state.iconColor}} >
                <LockFill alt="Puzzle Word Chart Lock" />
              </div>            
            </OverlayTrigger>
          
        )
      }
}

export default PuzzleWordChart
