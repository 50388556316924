import React, { Component } from 'react';
import { Form, Button } from 'react-bootstrap';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { ClockFill } from 'react-bootstrap-icons';
import gameService from '../services/gameService';

class TravelYearVerification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            travelYear: null,
            success: null,
            error: null
        }
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        })
    }

    handleSubmit = async event => {
        event.preventDefault();
        const response = await gameService.travelYearVerification(this.state.travelYear, this.props.currentYear);
        if (response.message === 'CORRECT') {
            this.setState({ success: true })
        } else {
            this.setState({ error: response.message });
        }
    }

    render() {
        const popover = (
            <Popover className="popover-basic">
                <Popover.Title as="h3">Time Travel Year Verification</Popover.Title>
                <Popover.Content>
                    {this.state.success ? 
                        <React.Fragment>
                                <h5>Time Travel Year Verification Success!</h5>
                                <p>You will need to travel to Year {this.state.travelYear} next!</p>
                        </React.Fragment>
                    
                    :
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Group controlId="travelYear">
                                <Form.Label>Verify Year To Time Travel To Next:</Form.Label>

                                <Form.Control type="text" placeholder="Input Year" onChange={this.handleChange} required />
                                {this.state.error ?
                                    <Form.Text className="text-muted">
                                        {this.state.error}
                                    </Form.Text>
                                    : ''}
                                <br />
                                <Button variant="primary" type="submit">Submit</Button>
                            </Form.Group>
                        </Form>
                    }

                </Popover.Content>
            </Popover>
        );

        return (
            <OverlayTrigger
                trigger="click"
                placement="auto"
                overlay={popover}
                rootClose={true}
                transition={false}
                onToggle={() => this.setState({ error: null })}
            >
                <div className="game-icons" style={this.props.positioning}>
                    <ClockFill alt="Travel Year Verification" />
                </div>
            </OverlayTrigger>
        )
    }
}

export default TravelYearVerification
