import React, { Component } from 'react';
import { Form, Button } from 'react-bootstrap';


class PasscodeForm extends Component {
    render() {
        return (
            <div>
                { this.props.success ? 
                    <React.Fragment>
                        { !this.props.lockedChest ? 
                            <React.Fragment>
                                <h3>Congratulations, you have solved this!</h3>
                                <h5>You have received the following elements:</h5>
                                <ul>
                                    <li>Elson: {this.props.elementsReceived.elson}</li>
                                    <li>Onoza: {this.props.elementsReceived.onoza}</li>
                                    <li>Robell: {this.props.elementsReceived.robell}</li>
                                    <li>Titanson: {this.props.elementsReceived.titanson}</li>
                                    <li>Caslight: {this.props.elementsReceived.caslight}</li>
                                    <li>Gnoson: {this.props.elementsReceived.gnoson}</li>
                                </ul>
                            </React.Fragment>
                        : ''
                        }

                    </React.Fragment>
                :
                    <Form onSubmit={this.props.handleSubmit}>
                        <Form.Group controlId="passcodeInput">
                            <Form.Label>Passcode {this.props.numberOfDigits}</Form.Label>
                            <Form.Control type="text" placeholder="Enter passcode" onChange={this.props.handleChange} required/>
                            {this.props.error ?
                                <Form.Text className="text-muted">
                                    {this.props.error}
                                </Form.Text>
                                : ''}
                            <br />
                            <Button variant="primary" type="submit">Submit</Button>
                        </Form.Group>
                    </Form>
                }

            </div>
        )
    }
}

export default PasscodeForm
