import React, { Component } from 'react';
import { Form, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { InfoCircleFill } from 'react-bootstrap-icons';
import { withRouter } from 'react-router-dom';
import gameService from '../services/gameService';

class TimeMachine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputCompound: 'Empty',
            timeTravelYear: '',
        }
    }

    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    }

    handleSubmit = async event => {
        event.preventDefault();
        const response = await gameService.timeTravelMachine(this.state.inputCompound, this.state.timeTravelYear, this.props.currentYear);
        if (response.message === 'CORRECT') {
            const timeTravelYear = this.state.timeTravelYear;
            alert(`Ting... Ting... Ting.... The Time Travel Machine transported you to Year ${this.state.timeTravelYear}...`);
            this.props.updateCompoundsInventory(response.compoundsUsed, 'deduct');
            this.props.updateCurrentYear(parseInt(this.state.timeTravelYear));
            this.setState({ inputCompound: 'Empty', timeTravelYear: ''});

            if (response.completedGame) {
                this.props.history.push(`/year-${timeTravelYear}-end`);
            } else {
                this.props.history.push(`/year-${timeTravelYear}`);
            }
            
            
        } else {
            alert('Time Travel Machine whirls... but nothing happens after that.');
        }
    }

    render() {
        const compoundsInventory = this.props.compoundsInventory;

        const popover = (
            <Popover className="popover-basic">
                <Popover.Title as="h3">Time Travel Machine</Popover.Title>
                <Popover.Content>
                    <p>The Time Travel Machine will allow you to travel across time to a specific year of your choosing.</p>
                    <p>In order to time travel, you will need to insert a compound (taken from your Compounds Inventory) into the Time Travel Machine and input the Year which you are intending to Time Travel to.</p>
                    <p>Both the Inserted Compound and the Year To Time Travel To must be correct in order to time travel successfully.</p>
                    <p><strong>Don't worry - if you insert the wrong Compound and/or Year To Time Travel To and click the Submit button, you will not deplete your Compounds Inventory.</strong></p>
                </Popover.Content>
            </Popover>
        );

        return (
            <div className="time-machine game-controls-single-component">
                <h5 className="text-center">
                    Time Travel Machine
                    <div className="game-controls-info-icon">
                        <OverlayTrigger trigger="click" placement="auto" overlay={popover} rootClose={true} onToggle={() => this.setState({ error: null })}>
                            <InfoCircleFill className="lock-icon" alt="Compounds Synthesizer Info Icon" />
                        </OverlayTrigger>
                    </div>
                </h5>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Group controlId="inputCompound">
                        <Form.Label>Insert Compound:</Form.Label>
                        <Form.Control as="select" custom  value={this.state.inputCompound} onChange={this.handleChange} required>
                            <option value="Empty">Empty</option>
                            {
                                Object.keys(compoundsInventory).map( (compound, index) => {
                                    return (
                                        compoundsInventory[compound] > 0 ? <option value={compound} key={index}>{compound}</option> : ''
                                    )
                                })
                            }
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="timeTravelYear">
                        <Form.Label>Year To Time Travel To:</Form.Label>
                        <Form.Control type="year" placeholder="Enter Year" value={this.state.timeTravelYear} onChange={this.handleChange} required/>
                    </Form.Group>
                    <Button variant="primary" type="submit">Submit</Button>
                </Form>
            </div>
        )
    }
}

export default withRouter(TimeMachine);
