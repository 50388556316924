import React, { Component } from 'react';
import ElementsInventory from './ElementsInventory';
import CompoundsInventory from './CompoundsInventory';
import CompoundSynthesizer from './CompoundSynthesizer';
import TimeMachine from './TimeMachine';



class GameControls extends Component {
    render() {
        const elementsInventory= this.props.elementsInventory;
        const updateElementsInventory = this.props.updateElementsInventory;

        const compoundsInventory = this.props.compoundsInventory;
        const updateCompoundsInventory = this.props.updateCompoundsInventory;
        const resetCompoundsInventory = this.props.resetCompoundsInventory;

        const currentYear = this.props.currentYear;
        const updateCurrentYear = this.props.updateCurrentYear;

        return (
            <div className="game-controls">
                <div className="game-controls-components">
                    <ElementsInventory
                        elementsInventory={elementsInventory}
                    />
                    <CompoundSynthesizer
                        compoundsInventory={compoundsInventory}
                        elementsInventory={elementsInventory}
                        updateCompoundsInventory={updateCompoundsInventory}
                        updateElementsInventory={updateElementsInventory}
                    />
                    <CompoundsInventory
                        compoundsInventory={compoundsInventory}
                        updateElementsInventory={updateElementsInventory}
                        resetCompoundsInventory={resetCompoundsInventory}
                    />
                    <TimeMachine 
                        compoundsInventory={compoundsInventory}
                        updateCompoundsInventory= {updateCompoundsInventory}

                        currentYear={currentYear}
                        updateCurrentYear={updateCurrentYear}
                    />
                </div>
                
            </div>
        )
    }
}

export default GameControls;
