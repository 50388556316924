import React, { Component } from 'react';

class Home extends Component {

    render() {
        return (
            <div className="general-container">
            </div>
        )
    }
}

export default Home
