// Dependencies
const axios = require('axios');
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:4000';

// Method to build API End Point
const buildUrl = apiPath => {
    return BACKEND_URL + apiPath;
};

export default {
    async compoundSynthesizer(input) {
        try {
            const response = await axios.post(buildUrl('/compound-synthesizer'), { elements: input});
            return response.data.data;
        } catch(err) {
            console.log(err);
        }
    },

    async timeTravelMachine(compound, travelYear, currentYear) {
        try {
            const response = await axios.post(buildUrl('/time-travel-machine'), { compound: compound, travelYear: travelYear, currentYear: currentYear });
            return response.data.data;
        } catch(err) {
            console.log(err);
        }
    },

    async reverseSynthesis(input) {
        try {
            const response = await axios.post(buildUrl('/reverse-synthesis'), { compounds: input});
            return response.data.data;
        } catch(err) {
            console.log(err);
        }
    },

    async puzzleCalculatorDigits(input) {
        try {
            const response = await axios.post(buildUrl('/puzzles/calculator-digits'), { passcode: input} );
            return response.data.data;
        } catch(err) {
            console.log(err);
        }
    },

    async puzzleElements1(input) {
        try {
            const response = await axios.post(buildUrl('/puzzles/elements-1'), { passcode: input})
            return response.data.data;
        } catch(err) {
            console.log(err);
        }
    },

    async puzzleWordChart(input) {
        try {
            const response = await axios.post(buildUrl('/puzzles/wordchart'), { passcode: input});
            return response.data.data;
        } catch(err) {
            console.log(err);
        }
    },

    async puzzleElements2(input) {
        try {
            const response = await axios.post(buildUrl('/puzzles/elements-2'), { passcode: input});
            return response.data.data;
        } catch(err) {
            console.log(err);
        }
    },

    async puzzleSkyscrapers(input) {
        try {
            const response = await axios.post(buildUrl('/puzzles/skyscrapers'), { passcode: input });
            return response.data.data;
        } catch(err) {
            console.log(err);
        }
    },

    async puzzleOptometrist(input) {
        try {
            const response = await axios.post(buildUrl('/puzzles/optometrist'), { passcode: input });
            return response.data.data;
        } catch(err) {
            console.log(err);
        }
    },

    async puzzleSafe(input) {
        try {
            const response = await axios.post(buildUrl('/puzzles/safe'), { passcode: input });
            return response.data.data;
        } catch(err) {
            console.log(err);
        }
    },

    async puzzleClocks(input) {
        try {
            const response = await axios.post(buildUrl('/puzzles/clocks'), { passcode: input });
            return response.data.data;
        } catch(err) {
            console.log(err);
        }
    },

    async lockedChest(input) {
        try {
            const response = await axios.post(buildUrl('/puzzles/locked-chest'), { passcode: input });
            return response.data.data;
        } catch(err) {
            console.log(err);
        }
    },

    async puzzleCaslight() {
        try {
            const response = await axios.post(buildUrl('/puzzles/caslight-slider'), { passcode: '136' });
            return response.data.data;
        } catch(err) {
            console.log(err);
        }
    },
    
    async travelYearVerification(travelYear, currentYear) {
        try {
            const response = await axios.post(buildUrl('/travel-year-verification'), { travelYear: travelYear, currentYear: currentYear.toString()});
            return response.data.data;
        } catch(err) {
            console.log(err);
        }
    }
}