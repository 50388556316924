import React, { Component } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { GeoAltFill } from 'react-bootstrap-icons';
import YearsGammaImage from '../../images/Years_Gamma.png';

export class YearsGamma extends Component {
    render() {
        const popover = (
            <Popover className="popover-basic">
                <Popover.Title as="h3">Years Gamma</Popover.Title>
                <Popover.Content>
                    <img src={YearsGammaImage} className="popover-images" alt="Puzzle Calculator Digits" />
                </Popover.Content>
            </Popover>
        );

        return (
            <OverlayTrigger
                trigger="click"
                placement="auto"
                overlay={popover}
                rootClose={true}
                transition={false}
                onToggle={() => this.setState({ error: null })}
            >
                <div className="game-icons years-Gamma" style={this.props.positioning}>
                    <GeoAltFill className="lock-icon" alt="Years Gamma Clue" />
                </div>
            </OverlayTrigger>
        )
    }
}

export default YearsGamma
