import React, { Component } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import PasscodeForm from './PasscodeForm';
import { LockFill } from 'react-bootstrap-icons';
import gameService from '../../services/gameService';

class LockedChest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            passcodeInput: '',
            success: null,
            error: null,
            iconColor: "red"
        }
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        })
    }

    handleSubmit = async event => {
        event.preventDefault();
        if (this.props.lockedChest) {
            console.log('already answered correctly');
        } else {
            const response = await gameService.lockedChest(this.state.passcodeInput);
            if (response.message === 'CORRECT') {
                this.setState({ success: true, iconColor: "green" })
                this.props.updateLockedChest();
            } else {
                this.setState({ error: response.message });
            }
        }
    }


    render() {
        const popover = (
            <Popover className="popover-basic">
                <Popover.Title as="h3">Locked Chest</Popover.Title>
                <Popover.Content>
                    
                    { this.props.lockedChest ?
                        <React.Fragment>
                            <h5>You have managed to open this box!</h5>
                            <img src="https://res.cloudinary.com/dqykhzxtl/image/upload/v1603791788/virtual-time-travel/PlotTwistMessage_y61aqk.jpg" className="popover-images" alt="plot-twist-message" />
                        </React.Fragment>
                    :
                        <React.Fragment>
                            <p>This chest is locked and requires a passcode to open it.</p>
                            <PasscodeForm
                                handleChange={this.handleChange}
                                handleSubmit={this.handleSubmit}
                                success={this.state.success}
                                error={this.state.error}
                                numberOfDigits={"(3-Digits)"}
                                lockedChest={true}
                            />
                        </React.Fragment>
                    }

                </Popover.Content>
            </Popover>
        );

        return (
            <OverlayTrigger
                trigger="click"
                placement="auto"
                overlay={popover}
                rootClose={true}
                transition={false}
                onToggle={() => this.setState({ error: null })}
            >
                <div className="game-icons" id="locked-chest" style={ {color: this.state.iconColor}}>
                    <LockFill alt="Locked Chest" />
                </div>
            </OverlayTrigger>

        )
    }
}

export default LockedChest
