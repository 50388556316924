import React, { Component } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { GeoAltFill } from 'react-bootstrap-icons';

class PeriodicTable extends Component {
    render() {
        const popover = (
            <Popover className="popover-basic">
                <Popover.Title as="h3">Periodic Table</Popover.Title>
                <Popover.Content>
                    <img src="https://res.cloudinary.com/dqykhzxtl/image/upload/v1603323293/virtual-time-travel/Periodic_Table_xn3hix.png" className="popover-images" alt="Periodic Table" />
                </Popover.Content>
            </Popover>
        );

        return (
            <OverlayTrigger
                trigger="click"
                placement="auto"
                overlay={popover}
                rootClose={true}
                transition={false}
                onToggle={() => this.setState({ error: null })}
            >
                <div className="game-icons years-alpha" style={this.props.positioning}>
                    <GeoAltFill alt="Periodic Table" />
                </div>
            </OverlayTrigger>
        )
    }
}

export default PeriodicTable
