import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { ProgressBar } from 'react-bootstrap';
import { VolumeMuteFill, VolumeUpFill } from 'react-bootstrap-icons';
import Year2398ContainerBackground from '../images/Year-2398-background.jpg';
import SirenAudio from '../audio/Siren.mp3';

// Puzzles
import LockedChest from './puzzles/LockedChest';
import PuzzleCaslight from './puzzles/PuzzleCaslight';

// Clues
import YearsAlpha from '../components/clues/YearsAlpha';
import YearsDelta from '../components/clues/YearsDelta';
import YearsGamma from '../components/clues/YearsGamma';
import ElementInfoCards from '../components/clues/ElementInfoCards';
import CompoundRecipes from '../components/clues/CompoundRecipes';
import CaslightMachine from '../components/clues/CaslightMachine';


class Year2398 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            year: 2398,
            siren: false,
            caslightInserted: false,
            lockedChest: false,
            puzzleCaslight: false
        }

    }

    updatePuzzleStatus = puzzle => {
        this.setState({ [puzzle]: true });
        this.props.updateTotalPuzzlesCompleted('add');
    }

    updateCaslightInserted = () => {
        this.setState({ caslightInserted: true });
    }

    playSirenAudio = () => {
        const sirenAudio = document.getElementById('siren-audio');
        sirenAudio.play();
        this.setState({ siren: true });
    }

    pauseSirenAudio = () => {
        const sirenAudio = document.getElementById('siren-audio');
        sirenAudio.pause();
        this.setState({ siren: false });
    }

    componentDidMount() {
        if (this.props.currentYear !== this.state.year) {
            const elements = {
                elson: 4,
                onoza: 2,
                robell: 1,
                titanson: 2,
                caslight: 1,
                gnoson: 2,
            };
            this.props.updateElementsInventory(elements, 'custom');
            this.props.updateCurrentYear(2398);
            this.props.updateTotalPuzzlesCompleted('custom', 5);
        };

    }

    updateLockedChest = () => {
        this.setState({ lockedChest: true });
    }

    render() {
        const yearContainer2398 = {
            backgroundImage: `url(${Year2398ContainerBackground})`,
            backgroundSize: 'cover'
        };

        const year2398 = {
            backgroundImage: `url("https://res.cloudinary.com/dqykhzxtl/image/upload/v1603434039/virtual-time-travel/Virtual_Time_Travel_2398_e3r6yo.jpg")`
        };

        return (
            <div className="year-container" style={yearContainer2398}>
                { this.props.currentYear === this.state.year ? '' : <Redirect to={`/year-${this.props.currentYear}`} />}

                <h1 className="text-center">Year 2398</h1>

                <ProgressBar striped animated variant="warning" now={100} label={"PROGRESS: 100%"} />
                <div className="year-background" style={year2398}>

                    <audio id="siren-audio" loop style={{ "display": "none" }}>
                        <source src={SirenAudio}></source>
                        Your browser does not support this audio file
                    </audio>

                    {/* Siren Audio Controls */}
                    {this.state.caslightInserted ?
                        <div className="siren-audio-controls">
                            {this.state.siren ?
                                <VolumeUpFill onClick={this.pauseSirenAudio} />
                                :
                                <VolumeMuteFill onClick={this.playSirenAudio} />
                            }
                        </div>
                        : ''
                    }

                    <YearsAlpha positioning={{ right: "20%", bottom: "39%" }} />
                    <YearsDelta positioning={{ left: "20%", bottom: "40%" }} />
                    <YearsGamma positioning={{ left: "38%", top: "45%" }} />
                    <ElementInfoCards positioning={{ left: "17%", top: "33%" }} />
                    <CompoundRecipes positioning={{ right: "16%", top: "33%" }} />

                    <CaslightMachine positioning={{ left: "49%", bottom: "35%" }}
                        caslightInserted={this.state.caslightInserted}
                        updateCaslightInserted={this.updateCaslightInserted}
                        playSirenAudio={this.playSirenAudio}

                        updateElementsInventory={this.props.updateElementsInventory}
                    />

                    {this.state.caslightInserted ?
                        <LockedChest
                            lockedChest={this.state.lockedChest}
                            updateLockedChest={this.updateLockedChest}
                        />
                        : ''
                    }

                    {this.state.lockedChest ?
                        <PuzzleCaslight
                            updateElementsInventory={this.props.updateElementsInventory}
                            updatePuzzleStatus={this.updatePuzzleStatus}
                        />
                        : ''
                    }
                </div>
            </div>
        )
    }
}

export default Year2398