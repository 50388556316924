import React, { Component } from 'react'

class PuzzleSliderCaslight extends Component {
    render() {
        const { tile1, tile2, tile3, tile4, tile5, tile6 } = this.props.tiles;
        const imgSrc = (tile) => {
            if (tile) {
                return `https://res.cloudinary.com/dqykhzxtl/image/upload/v1603936332/virtual-time-travel/Slider-${tile}.jpg`;
            } else {
                return "https://res.cloudinary.com/dqykhzxtl/image/upload/v1603938625/virtual-time-travel/Slider-0.jpg";
            }
            
        }

        return (
            <div>
                <p>Solve the following slider puzzle to retrieve the elements inside</p>
                <div className="puzzle-slider-caslight">
                    <div className="slider-row">
                        <div className="slider-tile">
                            <img id="tile1" onClick={this.props.tileClicked} src={imgSrc(tile1)} alt={`Slider-${tile1}`}/>
                            {tile1}
                        </div>
                        <div className="slider-tile">
                            <img id="tile2" onClick={this.props.tileClicked} src={imgSrc(tile2)} alt={`Slider-${tile2}`}/>
                        </div>
                        <div className="slider-tile">
                            <img id="tile3" onClick={this.props.tileClicked} src={imgSrc(tile3)} alt={`Slider-${tile3}`}/>
                        </div>
                    </div>
                    <div className="slider-row">
                        <div className="slider-tile">
                            <img id="tile4" onClick={this.props.tileClicked} src={imgSrc(tile4)} alt={`Slider-${tile4}`}/>
                        </div>
                        <div className="slider-tile">
                            <img id="tile5" onClick={this.props.tileClicked} src={imgSrc(tile5)} alt={`Slider-${tile5}`}/>
                        </div>
                        <div className="slider-tile">
                            <img id="tile6" onClick={this.props.tileClicked} src={imgSrc(tile6)} alt={`Slider-${tile6}`}/>
                        </div>
                    </div>
                </div>
                { this.props.success ? 
                    <React.Fragment>
                    <h3>Congratulations, you have solved this!</h3>
                    <h5>You have received the following elements:</h5>
                    <ul>
                        <li>Elson: {this.props.elementsReceived.elson}</li>
                        <li>Onoza: {this.props.elementsReceived.onoza}</li>
                        <li>Robell: {this.props.elementsReceived.robell}</li>
                        <li>Titanson: {this.props.elementsReceived.titanson}</li>
                        <li>Caslight: {this.props.elementsReceived.caslight}</li>
                        <li>Gnoson: {this.props.elementsReceived.gnoson}</li>
                    </ul>
                </React.Fragment>
                : '' }
            </div>
        )
    }
}

export default PuzzleSliderCaslight
