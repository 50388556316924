import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { ProgressBar } from 'react-bootstrap';
import PuzzleSafe from './puzzles/PuzzleSafe';
import PuzzleClocks from './puzzles/PuzzleClocks';
import Year1977ContainerBackground from '../images/Year-1977-background.jpg';

// Clues
import YearsAlpha from '../components/clues/YearsAlpha';
import YearsDelta from '../components/clues/YearsDelta';
import YearsGamma from '../components/clues/YearsGamma';
import ElementInfoCards from '../components/clues/ElementInfoCards';
import CompoundRecipes from '../components/clues/CompoundRecipes';
import Safe from '../components/clues/Safe';
import Clocks from '../components/clues/Clocks';
import TimeMachineHologrid from '../components/clues/TimeMachineHologrid';
import TimeTravelClue3 from '../components/clues/TimeTravelClue3';

// Travel Year Verification
import TravelYearVerification from './TravelYearVerification';

class Year1977 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            puzzleSafe: false,
            puzzleClocks: false,
            year: 1977
        }
    }

    updatePuzzleStatus = (puzzle) => {
        const puzzleStatus = puzzle;
        this.setState({ [puzzleStatus]: true });
        this.props.updateTotalPuzzlesCompleted('add');
    }

    componentDidMount() {
        if (this.props.currentYear !== this.state.year) {
            const elements = {
                elson: 0,
                onoza: 1,
                robell: 2,
                titanson: 0,
                caslight: 0,
                gnoson: 3,
            };
            this.props.updateElementsInventory(elements, 'custom');
            this.props.updateCurrentYear(1977);
            this.props.updateTotalPuzzlesCompleted('custom', 3);
        }
    }

    render() {

        const yearContainer1977 = {
            backgroundImage: `url(${Year1977ContainerBackground})`,
            backgroundSize: 'cover'
        };

        const year1977 = {
            backgroundImage: `url("https://res.cloudinary.com/dqykhzxtl/image/upload/v1603437340/virtual-time-travel/Virtual_Time_Travel_1977_mrssge.jpg")`
        }

        return (
            <div className="year-container" style={yearContainer1977}>
                { this.props.currentYear === this.state.year ? '' : <Redirect to={`/year-${this.props.currentYear}`} />}
                <h1 className="text-center text-white">Year 1977</h1>
                <ProgressBar striped animated variant="warning" now={75} label={"PROGRESS: 75%"} />
                <div className="year-background" style={year1977}>
                    <PuzzleSafe 
                        puzzleSafe = {this.state.puzzleSafe}
                        updatePuzzleStatus={this.updatePuzzleStatus}

                        elementsInventory = { this.props.elementsInventory }
                        updateElementsInventory = {this.props.updateElementsInventory}

                        totalPuzzlesCompleted = {this.props.totalPuzzlesCompleted}
                        updateTotalPuzzlesCompleted = {this.props.updateTotalPuzzlesCompleted}
                    />

                    <PuzzleClocks 
                        puzzleClocks = { this.state.puzzleClocks }
                        updatePuzzleStatus = { this.updatePuzzleStatus }

                        elementsInventory = {this.props.elementsInventory}
                        update updateElementsInventory = {this.props.updateElementsInventory}

                        totalPuzzlesCompleted = { this.props.totalPuzzlesCompleted }
                        updateTotalPuzzlesCompleted = {this.props.updateTotalPuzzlesCompleted}
                    />

                    <YearsAlpha positioning={{ left: "20%", bottom: "20%"}}/>
                    <YearsDelta positioning={{ left: "13%", bottom: "32%"}}/>
                    <YearsGamma positioning={{ left: "6%", bottom: "20%"}}/>
                    <ElementInfoCards positioning={{ right: "13%", top: "36%"}}/>
                    <CompoundRecipes positioning={{ left: "18%", top: "17%" }} />
                    <Safe positioning={{ left: "49%", top: "0%" }} />
                    <Clocks positioning={{ left: "49%", top: "25%" }} />   
                    <TimeMachineHologrid positioning={{ left: "49%", bottom: "45%" }} />
                    <TimeTravelClue3 positioning={{ right: "36%", bottom: "35%" }} />

                    <TravelYearVerification 
                        positioning={{ left: "40%", bottom: "35%" }}
                        currentYear = {this.state.year}
                    />
                </div>
            </div>
        )
    }
}

export default Year1977
