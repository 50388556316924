// Dependencies
import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import Home from './components/Home';
import Year2020Start from './components/Year2020Start';
import Year1039 from './components/Year1039';
import Year1977 from './components/Year1977';
import Year2398 from './components/Year2398';
import Year2020End from './components/Year2020End';
import WaitingRoom from './components/WaitingRoom';
import Introduction from './components/Introduction';


import GameControls from './components/GameControls';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentYear: 2020,
      totalPuzzlesCompleted: 0,
      elementsInventory: {
        elson: 0,
        gnoson: 0,
        onoza: 0,
        robell: 0,
        titanson: 0,
        caslight: 0
      },
      compoundsInventory: {
        alpha: 0,
        beta: 0,
        gamma: 0,
        delta: 0
      }
    }
  }

  updateCurrentYear = year => {
    this.setState({ currentYear: year});
  }

  updateTotalPuzzlesCompleted = (option, value = 0) => {
    if (option === 'add') {
      const currentPuzzlesCompleted = this.state.totalPuzzlesCompleted;
      this.setState({ totalPuzzlesCompleted: currentPuzzlesCompleted + 1 });
    } else if (option === 'custom') {
      this.setState({ totalPuzzlesCompleted: value });
    }
    
  }

  updateElementsInventory = (elements, option) => {
    if (option === 'add') {
      const updatedInventory = {
        elson: this.state.elementsInventory.elson + elements.elson,
        gnoson: this.state.elementsInventory.gnoson + elements.gnoson,
        onoza: this.state.elementsInventory.onoza + elements.onoza,
        robell: this.state.elementsInventory.robell + elements.robell,
        caslight: this.state.elementsInventory.caslight + elements.caslight,
        titanson: this.state.elementsInventory.titanson + elements.titanson
      }
      this.setState({ elementsInventory: updatedInventory });
    } else if (option === 'deduct') {
      const updatedInventory = {
        elson: this.state.elementsInventory.elson - elements.elson,
        gnoson: this.state.elementsInventory.gnoson - elements.gnoson,
        onoza: this.state.elementsInventory.onoza - elements.onoza,
        robell: this.state.elementsInventory.robell - elements.robell,
        caslight: this.state.elementsInventory.caslight - elements.caslight,
        titanson: this.state.elementsInventory.titanson - elements.titanson
      }
      this.setState({ elementsInventory: updatedInventory });
    } else if (option === 'custom') {
      this.setState({ elementsInventory: elements});
    }
  }

  updateCompoundsInventory = (compounds, option) => {
    if (option === 'add') {
      const updatedInventory = {
        alpha: this.state.compoundsInventory.alpha + compounds.alpha,
        beta: this.state.compoundsInventory.beta + compounds.beta,
        gamma: this.state.compoundsInventory.gamma + compounds.gamma,
        delta: this.state.compoundsInventory.delta + compounds.delta,
      };
      this.setState({ compoundsInventory: updatedInventory });
    } else if (option === 'deduct') {
      const updatedInventory = {
        alpha: this.state.compoundsInventory.alpha - compounds.alpha,
        beta: this.state.compoundsInventory.beta - compounds.beta,
        gamma: this.state.compoundsInventory.gamma - compounds.gamma,
        delta: this.state.compoundsInventory.delta - compounds.delta,
      };
      this.setState({ compoundsInventory: updatedInventory});
    }
    
  }

  resetCompoundsInventory = () => {
    this.setState({
      compoundsInventory: {
        alpha: 0,
        beta: 0,
        gamma: 0,
        delta: 0
      }
    })
  }

  render() {
    const { elementsInventory, compoundsInventory } = this.state;
    const gameControls = (
      <GameControls
        puzzlesCompleted={this.state.puzzlesCompleted}

        currentYear = {this.state.currentYear}
        updateCurrentYear={this.updateCurrentYear}

        elementsInventory={elementsInventory}
        updateElementsInventory={this.updateElementsInventory}

        compoundsInventory={compoundsInventory}
        updateCompoundsInventory={this.updateCompoundsInventory}
        resetCompoundsInventory={this.resetCompoundsInventory}
      />
    )

    return (
      <div className="App">
        <Router>
          <Switch>
            <Route path="/year-2020" exact>
              <Year2020Start
                currentYear = {this.state.currentYear}
                
                totalPuzzlesCompleted={this.state.totalPuzzlesCompleted}
                updateTotalPuzzlesCompleted={this.updateTotalPuzzlesCompleted}

                elementsInventory={elementsInventory}
                updateElementsInventory={this.updateElementsInventory}
              />
              {gameControls}
            </Route>

            <Route path="/year-1039" exact>
              <Year1039 
                currentYear = {this.state.currentYear}
                updateCurrentYear = { this.updateCurrentYear }
                
                totalPuzzlesCompleted = {this.state.totalPuzzlesCompleted}
                updateTotalPuzzlesCompleted={this.updateTotalPuzzlesCompleted}

                updateElementsInventory = { this.updateElementsInventory }
              />
              {gameControls}
            </Route>

            <Route path="/year-1977" exact>
              <Year1977 
                currentYear = {this.state.currentYear}
                updateCurrentYear = { this.updateCurrentYear }

                totalPuzzlesCompleted = {this.state.totalPuzzlesCompleted}
                updateTotalPuzzlesCompleted = { this.updateTotalPuzzlesCompleted }

                updateElementsInventory = { this.updateElementsInventory }
              />
              {gameControls}
            </Route>

            <Route path="/year-2398" exact>
              <Year2398 
                currentYear = {this.state.currentYear}
                updateCurrentYear = {this.updateCurrentYear}

                totalPuzzlesCompleted = {this.state.totalPuzzlesCompleted}
                updateTotalPuzzlesCompleted = { this.updateTotalPuzzlesCompleted }

                updateElementsInventory = {this.updateElementsInventory}
              />
              {gameControls}
            </Route>

            <Route path="/year-2020-end" component={Year2020End} 
              currentYear = {this.state.currentYear}
            />

            <Route path="/waiting-room" exact component={WaitingRoom} />

            <Route path="/introduction" exact component={Introduction} />

            <Route path="/" component={Home} />
          </Switch>
        </Router>

      </div>
    )
  }
}

export default App

