import React, { Component } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { GeoAltFill } from 'react-bootstrap-icons';
import TimeTravelClue2Image from '../../images/TimeTravelClue2.png';

class TimeTravelClue2 extends Component {
    render() {
        const popover = (
            <Popover className="popover-basic">
                <Popover.Title as="h3">Time Travel Clue 2</Popover.Title>
                <Popover.Content>
                    <img src={TimeTravelClue2Image} className="popover-images" alt="Time Travel Clue 2" />
                </Popover.Content>
            </Popover>
        );

        return (
            <OverlayTrigger
                trigger="click"
                placement="auto"
                overlay={popover}
                rootClose={true}
                transition={false}
                onToggle={() => this.setState({ error: null })}
            >
                <div className="game-icons" style={this.props.positioning}>
                    <GeoAltFill alt="Time Travel Clue 2" />
                </div>
            </OverlayTrigger>
        )
    }
}

export default TimeTravelClue2
