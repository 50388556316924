import React, { Component } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import PasscodeForm from './PasscodeForm';
import { LockFill } from 'react-bootstrap-icons';
import gameService from '../../services/gameService';

class PuzzleClocks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            passcodeInput: '',
            success: null,
            error: null,
            elementsReceived: null,
            iconColor: "red"
        }
    }

    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    }

    handleSubmit = async event => {
        event.preventDefault();
        if (this.props.puzzleClocks) {
            console.log('already answered correctly');
        } else {
            const response = await gameService.puzzleClocks(this.state.passcodeInput);
            if (response.message === 'CORRECT') {
                this.props.updateElementsInventory(response.elements, 'add');
                this.setState({ 
                    success: true,
                    elementsReceived: response.elements,
                    iconColor: "green"
                });
                this.props.updatePuzzleStatus('puzzleClocks');
            } else {
                this.setState({ error: response.message});
            }
        }
    }

    componentDidMount() {
        if (this.props.totalPuzzlesCompleted >= 7) this.setState({ success: true });
    }

    render() {
        const popover = (
            <Popover className="popover-basic">
                <Popover.Title as="h3">Puzzle: Clocks</Popover.Title>
                <Popover.Content>
                    <h1>Puzzle: Clocks</h1>
                    <img src="https://res.cloudinary.com/dqykhzxtl/image/upload/v1603324136/virtual-time-travel/Puzzle_Clocks_ae70fy.jpg" className="popover-images" alt="Puzzle Clocks" />
                    <p><i>Hint: This puzzle is solved with 1 other related clue(s) in the room.</i></p>
                    <PasscodeForm
                        handleChange={this.handleChange}
                        handleSubmit={this.handleSubmit}
                        elementsReceived={this.state.elementsReceived}
                        success={this.state.success}
                        error={this.state.error}
                        numberOfDigits={"(3-Digits)"}
                    />                        
                </Popover.Content>
            </Popover>
        );
        return (
            <div>
                <OverlayTrigger
                    trigger="click"
                    placement="auto"
                    overlay={popover}
                    rootClose={true}
                    transition={false}
                    onToggle={() => this.setState({ error: null })}
                >
                    <div className="game-icons" id="puzzle-clocks" style={{color: this.state.iconColor}}>
                        <LockFill alt="Puzzle Elements 2 Lock" />
                    </div>
                </OverlayTrigger>
            </div>
        )
    }
}

export default PuzzleClocks;
