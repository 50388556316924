import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { ProgressBar } from 'react-bootstrap';
import Year2020ContainerBackground from '../images/Year-2020-background.jpg';

// Puzzles
import PuzzleWordChart from './puzzles/PuzzleWordChart';

// Clues
import YearsAlpha from '../components/clues/YearsAlpha';
import YearsDelta from '../components/clues/YearsDelta';
import YearsGamma from '../components/clues/YearsGamma';
import ElementInfoCards from '../components/clues/ElementInfoCards';
import CompoundRecipes from '../components/clues/CompoundRecipes';
import WordChartClues from '../components/clues/WordChartClues';
import TimeMachineHologrid from '../components/clues/TimeMachineHologrid';
import TimeTravelClue1 from '../components/clues/TimeTravelClue1';

// Travel Year Verification
import TravelYearVerification from './TravelYearVerification';


class Year2020Start extends Component {
    constructor(props) {
        super(props);
        this.state = {
            puzzleCalculatorDigits: false,
            puzzleElements1: false,
            puzzleWordChart: false,
            year: 2020,
        }
    }

    updatePuzzleStatus = (puzzle) => {
        const puzzleStatus = puzzle;
        this.setState({
            [puzzleStatus]: true
        });
        this.props.updateTotalPuzzlesCompleted('add');
    }


    render() {

        const yearContainer2020 = {
            backgroundImage: `url(${Year2020ContainerBackground})`,
            backgroundSize: 'cover'
        };

        const year2020 = {
            backgroundImage: `url("https://res.cloudinary.com/dqykhzxtl/image/upload/v1605235468/virtual-time-travel/Virtual_Time_Travel_Year_2020_iddrcy.jpg")`
        };

        return (
            <div className="year-container" style={yearContainer2020}>
                { this.props.currentYear === this.state.year ? '' : <Redirect to={`/year-${this.props.currentYear}`} />}

                <h1 className="text-center text-white">Year 2020</h1>
                
                <ProgressBar striped animated variant="warning" now={25} label={"PROGRESS: 25%"} />
                
                <div className="year-background" style={year2020}>
                    {/* <PuzzleCalculatorDigits
                        puzzleCalculatorDigits={this.state.puzzleCalculatorDigits}
                        updatePuzzleStatus={this.updatePuzzleStatus}

                        elementsInventory={this.props.elementsInventory}
                        updateElementsInventory={this.props.updateElementsInventory}

                        totalPuzzlesCompleted={this.props.totalPuzzlesCompleted}
                        updateTotalPuzzlesCompleted={this.props.updateTotalPuzzlesCompleted}
                    /> */}

                    {/* <PuzzleElements1
                        puzzleElements1={this.state.puzzleElements1}
                        updatePuzzleStatus={this.updatePuzzleStatus}

                        elementsInventory={this.props.elementsInventory}
                        updateElementsInventory={this.props.updateElementsInventory}

                        totalPuzzlesCompleted={this.props.totalPuzzlesCompleted}
                        updateTotalPuzzlesCompleted={this.props.updateTotalPuzzlesCompleted}
                    /> */}

                    <PuzzleWordChart
                        puzzleWordChart={this.state.puzzleWordChart}
                        updatePuzzleStatus={this.updatePuzzleStatus}

                        elementsInventory={this.props.elementsInventory}
                        updateElementsInventory={this.props.updateElementsInventory}

                        totalPuzzlesCompleted={this.props.totalPuzzlesCompleted}
                        updateTotalPuzzlesCompleted={this.props.updateTotalPuzzlesCompleted}
                    />

                    <YearsAlpha positioning={{ left: "17%", bottom: "42%" }} />
                    <YearsDelta positioning={{ left: "17%", top: "40%" }} />
                    <YearsGamma positioning={{ left: "26.5%", top: "41%" }} />
                    <ElementInfoCards positioning={{ right: "10%", top: "30%" }} />
                    <CompoundRecipes positioning={{ right: "5%", bottom: "35%" }} />
                    <WordChartClues positioning={{ right: "28%", bottom: "43%" }} />
                    <TimeMachineHologrid positioning={{ left: "33%", top: "45%" }} />
                    <TimeTravelClue1 positioning={{ left: "40%", top: "51%" }} />

                    <TravelYearVerification 
                        positioning={{ left: "28%", bottom: "40%" }}
                        currentYear = {this.state.year}
                    />
                </div>


            </div>
        )
    }
}

export default Year2020Start
