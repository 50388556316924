import React, { Component } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import PasscodeForm from './PasscodeForm';
import { LockFill } from 'react-bootstrap-icons';
import gameService from '../../services/gameService';
import PuzzleSafeImage from '../../images/Puzzle_Safe.png'

class PuzzleSafe extends Component {
    constructor(props) {
        super(props);
        this.state = {
            passcodeInput: '',
            success: null,
            error: null,
            elementsReceived: null,
            iconColor: "red"
        }
    }

    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    }

    handleSubmit = async event => {
        event.preventDefault();
        if (this.props.puzzleSafe) {
            console.log('already answered correctly');
        } else {
            const response = await gameService.puzzleSafe(this.state.passcodeInput);
            if (response.message === 'CORRECT') {
                this.props.updateElementsInventory(response.elements, 'add');
                this.setState({ 
                    success: true,
                    elementsReceived: response.elements,
                    iconColor: "green"
                });
                this.props.updatePuzzleStatus('puzzleSafe');
            } else {
                this.setState({ error: response.message });
            }
        }
    }


    componentDidMount() {
        if (this.props.totalPuzzlesCompleted >= 7) this.setState({ success: true });
    }

    render() {
        const popover = (
            <Popover className="popover-basic">
                <Popover.Title as="h3">Puzzle: Safe</Popover.Title>
                <Popover.Content>
                    <img src={PuzzleSafeImage} className="popover-images" alt="Puzzle Safe" />
                    <p><i>Hint: This puzzle is solved with 1 other related clue(s) in the room.</i></p>
                    <PasscodeForm
                        handleChange={this.handleChange}
                        handleSubmit={this.handleSubmit}
                        success={this.state.success}
                        error={this.state.error}
                        elementsReceived={this.state.elementsReceived}
                        numberOfDigits={"(3-Digits)"}
                    />
                </Popover.Content>
            </Popover>
        );

        return (
            <OverlayTrigger
                trigger="click"
                placement="auto"
                overlay={popover}
                rootClose={true}
                transition={false}
                onToggle={() => this.setState({ error: null })}
            >
                <div className="game-icons" id="puzzle-safe" style={{color: this.state.iconColor}}>
                    <LockFill alt="Puzzle Safe Lock" />
                </div>
            </OverlayTrigger>
        )
    }
}

export default PuzzleSafe;
