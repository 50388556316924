import React, { Component } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { GeoAltFill } from 'react-bootstrap-icons';
import YearsAlphaImage from '../../images/Years_Alpha.png';

export class YearsAlpha extends Component {
    render() {
        const popover = (
            <Popover className="popover-basic">
                <Popover.Title as="h3">Years Alpha</Popover.Title>
                <Popover.Content>
                    <img src={YearsAlphaImage} className="popover-images" alt="Puzzle Calculator Digits" />
                </Popover.Content>
            </Popover>
        );

        return (
            <OverlayTrigger
                trigger="click"
                placement="auto"
                overlay={popover}
                rootClose={true}
                transition={false}
                onToggle={() => this.setState({ error: null })}
            >
                <div className="game-icons years-alpha" style={this.props.positioning}>
                    <GeoAltFill alt="Years Alpha Clue" />
                </div>
            </OverlayTrigger>
        )
    }
}

export default YearsAlpha
