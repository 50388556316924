import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
const imagePrefix = 'https://res.cloudinary.com/dqykhzxtl/image/upload/v1603179081/virtual-time-travel/';

class ClocksWidget extends Component {
    constructor(props) {
        super(props);
        this.state={
            score: 0,
            die1: 0,
            die2: 0,
            die3: 0,
            die4: 0,
            die5: 0,
            die1Image: null,
            die2Image: null,
            die3Image: null,
            die4Image: null,
            die5Image: null
        }
    }

    handleClick = event => {
        event.preventDefault();
        this.rollDies();
    }

    rollDies = () => {
        const die1Value = Math.floor(Math.random() * 6 + 1);
        const die2Value = Math.floor(Math.random() * 6 + 1);
        const die3Value = Math.floor(Math.random() * 6 + 1);
        const die4Value = Math.floor(Math.random() * 6 + 1);
        const die5Value = Math.floor(Math.random() * 6 + 1);

        const score = this.checkScore([die1Value, die2Value, die3Value, die4Value, die5Value]);
        
        this.setState({
            die1: die1Value,
            die2: die2Value,
            die3: die3Value,
            die4: die4Value,
            die5: die5Value,
            score: score
        })
    }

    checkScore = (array) => {
        let sum = 0;
        array.forEach(value => {
            if (value === 3) {
                sum+= 2;
            } else if (value === 5) {
                sum+= 4;
            }
        });
        return sum;
    }

    componentDidMount() {
        this.rollDies();
    }

    render() {
        return (            
            <div className="clocks-widget">
                <div className="die-rolls-section">
                    <div className="die-roll"><img src={`${imagePrefix}Die${this.state.die1}.png`} alt="die-1"/></div>
                    <div className="die-roll"><img src={`${imagePrefix}Die${this.state.die2}.png`} alt="die-2"/></div>
                    <div className="die-roll"><img src={`${imagePrefix}Die${this.state.die3}.png`} alt="die-3"/></div>
                    <div className="die-roll"><img src={`${imagePrefix}Die${this.state.die4}.png`} alt="die-4"/></div>
                    <div className="die-roll"><img src={`${imagePrefix}Die${this.state.die5}.png`} alt="die-5"/></div>
                </div>
                <div className="clocks-widget-score">
                    {this.state.score}
                </div>
                <div className="around-the-clock text-center">Around The Clock</div>
                <p><i>Hint: You do not need to match the dice pictures here with the ones in the Puzzle Box.</i></p>
                <div className="roll-button">
                    <Button variant="info" onClick={this.handleClick}>Roll Again</Button>
                </div>

            </div>
        )
    }
}

export default ClocksWidget
