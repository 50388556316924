import React, { Component } from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { InfoCircleFill } from 'react-bootstrap-icons';
import gameService from '../services/gameService';

export class CompoundsInventory extends Component {
    handleSubmit = async event => {
        event.preventDefault();
        const response = await gameService.reverseSynthesis(this.props.compoundsInventory);
        if (response.elements) {
            this.props.updateElementsInventory(response.elements, 'add');
            this.props.resetCompoundsInventory();
            alert(response.message);
        } else {
            alert(response.message);
        }
    }

    render() {
        const { alpha, beta, gamma, delta } = this.props.compoundsInventory;

        const popover = (
            <Popover className="popover-basic">
                <Popover.Title as="h3">Compounds Inventory</Popover.Title>
                <Popover.Content>
                    <p>Your Compounds Inventory shows a breakdown of all the compounds that you have synthesized so far during your Virtual Time Travel Game.</p>
                    <p>If you had made a mistake and synthesized the wrong compound, you are able to reverse synthesize all your compounds in your Compounds Inventory back to their respective elements. The elements will then be added back to your Elements Inventory.</p>
                </Popover.Content>
            </Popover>
        );

        return (
            <div className="compounds-inventory game-controls-single-component">
                <h5 className="text-center">
                    Compounds Inventory
                    <div className="game-controls-info-icon">
                        <OverlayTrigger trigger="click" placement="auto" overlay={popover} rootClose={true} onToggle={() => this.setState({ error: null })}>
                            <InfoCircleFill className="lock-icon" alt="Compounds Synthesizer Info Icon" />
                        </OverlayTrigger>
                    </div>
                </h5>
                <ul>
                    <li>Alpha: {alpha}</li>
                    <li>Beta: {beta}</li>
                    <li>Gamma: {gamma}</li>
                    <li>Delta: {delta}</li>
                </ul>
                <Button variant="warning" onClick={this.handleSubmit}>Reverse Synthesis</Button>
            </div>
        )
    }
}

export default CompoundsInventory
