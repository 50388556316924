import React, { Component } from 'react';
import { OverlayTrigger, Popover, Button, Modal } from 'react-bootstrap';
import { GeoAltFill } from 'react-bootstrap-icons';

class CaslightMachine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
        };
    }

    handleOpenModal = () => {
        if (window.confirm('Insert Caslight element into the machine?')) {
            this.props.updateCaslightInserted();
            this.setState({ modal: true});
            this.props.playSirenAudio();
            this.props.updateElementsInventory({
                elson: 0,
                gnoson: 0,
                onoza: 0,
                robell: 0,
                titanson: 0,
                caslight: 1
            }, 'deduct')
        }
    }

    testButton = () => {
        this.props.playSirenAudio();
    }

    handleCloseModal = () => {
        this.setState({ modal: false});
    }

    render() {
        const popover = (
            <Popover className="popover-basic">
                <Popover.Title as="h3">Caslight Machine</Popover.Title>
                <Popover.Content>
                    <img src="https://res.cloudinary.com/dqykhzxtl/image/upload/v1603796063/virtual-time-travel/Note-From-Uncle_tplb03.png" className="popover-images message-from-uncle" alt="Message From Uncle" />
                    { 
                        this.props.caslightInserted ? 
                            <React.Fragment>
                                <p>Caslight has already been inserted.</p>
                                <p>A note slips out of the Caslight Machine. A 3-Digit number is scribbled on the note: <strong>515</strong>.</p>
                                <p>You suddenly noticed a locked chest in the room.</p>
                            </React.Fragment> 
                        : <Button variant="success" onClick={this.handleOpenModal}>Insert Caslight Into Machine</Button>
                    }
                    
                </Popover.Content>
            </Popover>
        );

        return (
            <React.Fragment>
                <OverlayTrigger
                    trigger="click"
                    placement="auto"
                    overlay={popover}
                    rootClose={true}
                    transition={false}
                    onToggle={() => this.setState({ error: null })}
                >
                    <div className="game-icons years-alpha" style={this.props.positioning}>
                        <GeoAltFill alt="Caslight Machine" />
                    </div>
                </OverlayTrigger>


                <Modal show={this.state.modal} onHide={this.handleCloseModal} keyboard={false} backdrop="static" style={{"zIndex" : "9999"}}>
                    <Modal.Header closeButton>
                        <Modal.Title>Caslight Inserted Into Machine!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>The Caslight Machine whirls.... but nothing happens after that.</p>
                        <p>Suddenly, the doors locked shut.</p>
                        <p>You heard shouting from above... "New human arrivals just came! LET'S GET THEM!", and an eerie siren begins sounding in the background...</p>
                        <p>A note slips out of the Caslight Machine. A 3-Digit number is scribbled on the note: <strong>515</strong>.</p>
                        <p>You suddenly noticed a locked chest in the room.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleCloseModal}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        )
    }
}

export default CaslightMachine
